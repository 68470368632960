/**
 * Regex matching legal entry identifiers for virtual POD entries; these are of
 * the form `${PODName}.${PODVirtualEntryName}`.
 */
export const POD_VIRTUAL_ENTRY_IDENTIFIER_REGEX = new RegExp(/([A-Za-z_]\w*)\.\$(signerPublicKey|contentID)$/);
/**
 * Regex matching legal names for POD virtual entries. Matches
 * `PODVirtualEntryName`.
 */
export const POD_VIRTUAL_NAME_REGEX = new RegExp(/^\$(signerPublicKey|contentID)$/);
// Single source of truth for tuple prefix (used internally).
// This should not be a valid {@link PODName} to avoid name clashes.
export const TUPLE_PREFIX = "$tuple";
/**
 * Semaphore V3 identity protocol string
 */
export const SEMAPHORE_V3 = "SemaphoreV3";
/**
 * Semaphore V4 identity protocol string
 */
export const SEMAPHORE_V4 = "SemaphoreV4";
/**
 * Converts a record of membership lists to one of membership sets.
 *
 * @param membershipLists the lists to convert
 * @returns a record of membership sets
 */
export function membershipListsToSets(membershipLists) {
    return Object.fromEntries(Object.entries(membershipLists).map((pair) => [
        pair[0],
        new Set(pair[1])
    ]));
}
