import { v3tov4Identity } from "./v4IdentityUtils.js";
export const SemaphoreIdentityPCDTypeName = "semaphore-identity-pcd";
export function isSemaphoreIdentityPCD(pcd) {
    return pcd.type === SemaphoreIdentityPCDTypeName;
}
export class SemaphoreIdentityPCD {
    constructor(id, claim) {
        this.type = SemaphoreIdentityPCDTypeName;
        this.claim = claim;
        this.proof = undefined;
        this.id = id;
        if (v3tov4Identity(claim.identityV3).export() !== claim.identityV4.export()) {
            throw new Error("v3tov4Identity(claim.identity) !== claim.identityV4");
        }
    }
}
