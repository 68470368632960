import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FieldLabel, HiddenText, Separator, styled } from "@pcd/passport-ui";
export const SemaphoreIdentityPCDUI = {
    renderCardBody: SemaphoreIdentityCardBody
};
function SemaphoreIdentityCardBody({ pcd }) {
    return (_jsxs(Container, { children: [_jsx("p", { children: "This PCD represents a Sempahore Identity, which can be used to send anonymous group signals, log into websites, etc." }), _jsx(Separator, {}), _jsx(FieldLabel, { children: "Semaphore Commitment" }), _jsx(HiddenText, { text: pcd.claim.identityV3.commitment.toString() })] }));
}
const Container = styled.div `
  padding: 16px;
  overflow: hidden;
  width: 100%;
`;
